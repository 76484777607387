import { FuseConfig } from '@fuse/types';

export const fuseConfig: FuseConfig = {
	colorTheme: 'theme-default',
	customScrollbars: true,
	banner: '',
	bgPage: '',
	bgMain: '',
	directorLogo: '',
	hqLogo: '',
	smallLogo: '',
	layout: {
		style: 'vertical-layout-1',
		width: 'fullwidth',
		navbar: {
			primaryBackground: '',
			secondaryBackground: '',
			customPrimaryBackground: '',
			customPrimaryForeground: '',
			customSecondaryBackground: '',
			customSecondaryForeground: '',
			folded: false,
			hidden: false,
			position: 'left',
			variant: 'vertical-style-2'
		},
		toolbar: {
			toolBarLogo: '',
			customBackgroundColor: true,
			background: '',
			customToolbarBackground: '',
			customToolbarForeground: '',
			hidden: false,
			position: 'below-static'
		},
		footer: {
			customBackgroundColor: true,
			background: '',
			customFooterBackground: '',
			customFooterForeground: '',
			hidden: false,
			position: 'below-static',
			email: '',
			facebook: '',
			instagram: '',
			twitter: '',
			youtube: '',
			linkedin: '',
			forum: ''
		},
		sidepanel: {
			hidden: false,
			position: 'right'
		},
		pageheader: {
			primaryBackground: '',
			customHeaderPrimaryBackground: '',
			customHeaderPrimaryForeground: ''
		},
		linkActiveColor: {
			primaryBackground: '',
			customLinkBackground: '',
			customLinkForeground: ''
		}
	}
};
